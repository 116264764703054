import React from "react";
import PropTypes from "prop-types";

const EPlanPermittedSection = (props) => {
	const { children, loggedInRole = "", role = [] } = props;

	return (
		<React.Fragment>
			{role.some(r => r === loggedInRole) ? children : null}
		</React.Fragment>
	);
};

EPlanPermittedSection.propTypes = {
	children: PropTypes.any,
	role: PropTypes.array
};

export default EPlanPermittedSection;