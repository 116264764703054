import React, { useEffect, useState } from "react";
import { Box, Icon, Text, Link } from "@chakra-ui/react";
import { colors } from "../../helpers/theme";
import { FaUser, FaList } from "react-icons/fa";
import { NavLink as RouterLink } from "react-router-dom";
import styled from "@emotion/styled";
import { User } from "../../helpers/storage";
import EPlanPermittedSection from "../../components/EPlanPermittedSection";
import Const from "../../helpers/constants";
import logo from "../../assets/images/logo/logo_og.png";
import IrclassLogo from "../../assets/images/logo/irclass_logo.png";

const NavLink = styled(Link)`
    color: ${colors.gray["800"]};
    padding: 1.2rem;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    z-index: 1;
`;

const NavIcon = styled(Icon)`
	width:25px;
	height:25px;
	padding:6px;
	border-radius:3px;
	background-color:${colors.gray["300"]}
`;

const SideMenu = () => {
	const [userInfo, setUserInfo] = useState({});

	useEffect(() => {
		setUserInfo(User.get("userInfo") || {});
	}, []);

	return (
		<Box className="open-drawer" border="1px" borderRightColor="gray.100" w={{ base: "0", lg: "15%" }} h="100vh" position="fixed">
			<Box px="50px" p="12px" display={{ md: "flex" }} justifyContent="left">
				<img src={logo} width="60%" borderRadius="3px" />
			</Box>
			<Box p="20px 0">
				<EPlanPermittedSection loggedInRole={userInfo?.ROLE_NAME || ""} role={Object.keys(Const.EPLAN_ROLES)}>
					<NavLink _activeLink={{ backgroundColor: "gray.50",borderRight:"3px solid #079" }} as={RouterLink} to="/dashboard">
						<NavIcon as={FaUser} />
						<Text textStyle="navItem" ml="15px">Dashboard</Text>
					</NavLink>
				</EPlanPermittedSection>
				<NavLink _activeLink={{ backgroundColor: "gray.50",borderRight:"3px solid #079" }} as={RouterLink} to={"/ror-listing"}>
					<NavIcon as={FaList} />
					<Text textStyle="navItem" ml="15px">My RoRs</Text>
				</NavLink>
				<EPlanPermittedSection loggedInRole={userInfo?.ROLE_NAME || ""} role={[Const.EPLAN_ROLES.RADM]}>
					<NavLink _activeLink={{ backgroundColor: "gray.50",borderRight:"3px solid #079" }} as={RouterLink} to={"/ror-templates"}>
						<NavIcon as={FaList} />
						<Text textStyle="navItem" ml="15px">RoR Templates</Text>
					</NavLink>
				</EPlanPermittedSection>
			</Box>
			<Box px="50px" p="12px" style={{ bottom: "10px" }} display={{ md: "flex" }} justifyContent="left" position="absolute">
				<img src={IrclassLogo} width="100%" borderRadius="3px" />
			</Box>
		</Box>
	);
};

export default SideMenu;

