import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { render } from "react-dom";
import routes from "./routes/routes";
import theme from "./helpers/theme";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const ePlanTheme = extendTheme(theme);
render(
	<Router>
		<ChakraProvider theme={ePlanTheme}>
			{/* <Suspense
				fallback={<Loader />}> */}
			{routes}
			{/* </Suspense> */}
		</ChakraProvider>
	</Router>,
	document.getElementById("app")
);
