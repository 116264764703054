import React, { useState } from "react";
import { Box, Button, Menu, MenuButton, MenuList, MenuItem, Avatar, Text, Icon } from "@chakra-ui/react";
import User from "../../helpers/current-user";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import styled from "@emotion/styled";
import logo from "../../assets/images/logo/logo_og.png";

const Header = () => {

	const HeaderWrapper = styled(Box)`
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    right: 0;
    z-index: 2;
`;
	const [profileName] = useState(() => {
		return User.get() && `${User.get().FIRST_NAME} ${User.get().LAST_NAME}`;
	});

	return (
		<HeaderWrapper border="1px" borderBottomColor="gray.100" mr="55px" w={{ base: "100vw", lg: "85%" }} ml={{ base: "0", lg: "15%" }} display="flex" alignItems="center" py={{ base: "5px", lg: "10px" }} px={{ base: "10px", lg: "20px" }}
			justifyContent="space-between">
			<Box display="flex" alignItems="center">
				<Button mr="10px" px="0" d="block"
					backgroundColor="white">
					<Icon as={HiOutlineMenuAlt1} w="7" h="7" color="gray.800" fontWeight="normal" />
				</Button>
				<Box display={{ md: "none" }}>
					<img src={logo} />
				</Box>
			</Box>
			<Box display="flex" alignItems="center">
				<Avatar mr="2" size="sm" name={profileName} src="https://bit.ly/broken-link" />
				<Menu>
					<MenuButton as={Button} display={{ xs: "none", md: "inline-block" }} backgroundColor="transparent">
						<Text textStyle="span">{profileName}</Text>
					</MenuButton>
					<MenuList>
						<MenuItem as="a" onClick={User.logout}>
							Logout
    					</MenuItem>
					</MenuList>
				</Menu>
			</Box>
		</HeaderWrapper>
	);
};

export default Header;