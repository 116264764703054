import React from "react";
import Footer from "./footer";
import LoaderOverlay from "../loader/loader";
import { Box } from "@chakra-ui/react";

const content = (props) => {

	const { children, SideMenu, Header } = props;
	
	return (
		<Box bg="white.50" minHeight="100vh">
			{Header && <Header {...children.props} />}
			{SideMenu && <SideMenu {...children.props} />}
			<Box pt="56px">
				{children}
			</Box>
			<LoaderOverlay active={false} />
			<Footer {...children.props} />
		</Box>
	);
};

export default content;