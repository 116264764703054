const constants = {
	PAGING: {
		LIMIT: 10,
		INITIAL_PAGE: 1
	},
	COMMON_PASSWORD: "epl@1",
	DATE_FORMAT: {
		LONG_DATE: "YYYY-MM-DD HH:mm:ss",
		CLIENT_DATE: "DD MMM YYYY",
		CLIENT_DATE_TIME: "DD MMM YYYY hh:mm A",
		SERVER_DATE: "YYYY-MM-DD",
		TIME: "hh:mm A",
	},
	ROLE: {
		ADMIN: "Administrator",
		SUPPORT: "Support Staff",
		PROJECT_MANAGER: "HO Project Manager",
		ASSIGNEE: "Assignee",
		SIGNATORY: "HOD"
	},
	EPLAN_ROLES: {
		RSIC: "RSIC",
		RSUR: "RSUR",
		RHOR: "RHOR",
		RSQA: "RSQA",
		RHOD: "RHOD",
		RHR: "RHR",
		RADM: "RADM"
	},
	UPLOADED_ROR_FILE_BASE_URL: "http://eplanrorapi.irclass.net:4000/",
	ROR_TEMPLATE_LOCATION: "http://192.0.0.23:881/Doc/Other/RequestC_Docs/RequestC/a.Forms_common_to_all_Sections/Details/",
	ROR_STATUS: { ALLOCATED: "Allocated", REVIEW_PENDING: "Review Pending", APPROVED: "Approved", CANCELLED: "Cancelled" },
	UPLOAD_FILE_TYPES: [".docx", ".pdf", ".zip"]
};

export default constants;
