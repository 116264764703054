import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Async from "react-code-splitting";
import Layout from "../shared/layout/content";
import SideMenu from "../shared/layout/sidemenu";
import Header from "../shared/layout/header";
import CurrentUser from "../helpers/current-user";

const LoginRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={props => {
		if (CurrentUser.loggedin()) {
			return <Redirect to={"/"} />
		} else {
			return (
				<Layout>
					<Component {...props} />
				</Layout>
			)
		}
	}} />
);

const PrivateRoute = ({ component: Component, permit = [], ...rest }) => {
	return (
		<Route {...rest} render={props => {
			if (CurrentUser.loggedin()) {
				return (
					<Layout SideMenu={SideMenu} Header={Header}>
						<Component {...props} />
					</Layout>
				)
			}
			else if (CurrentUser.loggedin()) {
				return <Redirect to="/not-found" />
			}
			else {
				return <Redirect to="/" />
			}
		}} />
	);
}

const PublicRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={props => {
		return (
			<Layout>
				<Component {...props} />
			</Layout>
		)
	}} />
);

const NotFound = props => <Async load={import("../pages/not-found")} componentProps={props} />;
const Login = props => <Async load={import("../pages/login")} componentProps={props} />;
const RedirectPage = props => <Async load={import("../pages/redirect")} componentProps={props} />;
const Dashboard = props => <Async load={import("../pages/dashboard")} componentProps={props} />;
const InitiateROR = props => <Async load={import("../pages/initiate-ror")} componentProps={props} />;
const ListingROR = props => <Async load={import("../pages/listing-ror")} componentProps={props} />;
const RORTemplates = props => <Async load={import("../pages/ror-templates")} componentProps={props} />;
const DetailsROR = props => <Async load={import("../pages/details-ror")} componentProps={props} />;
const NewRoRTemplate = props => <Async load={import("../pages/new-ror-template")} componentProps={props} />;

const routes = (
	<Switch>
		<PublicRoute exact path="/" component={Login} />
		<LoginRoute path="/login" component={Login} />
		<PublicRoute path="/redirect" component={RedirectPage} />
		<PrivateRoute path="/not-found" component={NotFound} />
		<PrivateRoute path="/dashboard" component={Dashboard} />
		<PrivateRoute path="/initiate-ror" component={InitiateROR} />
		<PrivateRoute path="/ror-listing" component={ListingROR} />
		<PrivateRoute path="/ror-templates" component={RORTemplates} />
		<PrivateRoute path="/ror-detail/:srfId/:rorId" component={DetailsROR} />
		<PrivateRoute path="/edit-ror-template/:templateId" component={NewRoRTemplate} />
		<PrivateRoute path="/new-ror-template" component={NewRoRTemplate} />
		<Redirect to="/" />
	</Switch>
);

export default routes;
